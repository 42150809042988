export default class SlickVehicleGallery {
    public static init() {
		const autoPlay = ($('.phyron-thumbnail').length > 0 ? false: true);
		if ($('.slick--vehicle-gallery-main').length > 0) {
			$('.slick--vehicle-gallery-main').slick({
				initialSlide: 0,
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				prevArrow: "<span class='slick-prev thumbs'>&lt;</span>",
				nextArrow: "<span class='slick-next thumbs'>&gt;</span>",
				asNavFor: '.slick--vehicle-gallery-thumbs',
				autoplay: false,
				autoplaySpeed: 3000,
				pauseOnHover: true,
				pauseOnFocus: true,
				variableWidth: true,
				responsive: [
					{
						breakpoint: 800,
						settings: {
							autoplay: false,
							variableWidth: false
						}
					}
				]
			});
			$('.slick--vehicle-gallery-thumbs').slick({
				slidesToShow: 6,
				lazyLoad: "ondemand",
				slidesToScroll: 1,
				responsive: [
					{
						breakpoint: 800,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1,
							autoplay: false
						}
					}
				],
				arrows: false,
				asNavFor: '.slick--vehicle-gallery-main',
				dots: false,
				centerMode: false,
				focusOnSelect: true,
				autoplay: false,
				autoplaySpeed: 3000,
				pauseOnHover: true,
				pauseOnFocus: true
			});
			$('.slick--vehicle-gallery-main').each(function () {
				(<any>$(this)).magnificPopup({
					delegate: 'a',
					type: 'image',
					gallery: {
						enabled: true
					}
				});
			});
			$( '.slick--vehicle-gallery-main' ).on( 'lazyLoaded', function( evt, slick, $img ) {

				$img
					// Find the parent <picture> tag of img
					.closest('picture')
					// Find <source> tags with data-lazy-srcset attribute
					.find('source[data-lazy-srcset]')
					// Copy data-lazy-srcset to srcset
					.each(function (i: any, $source: any) {
						$source = $($source);
						$source.attr('srcset', $source.data('lazy-srcset'));
					}); 
			
			} );
			$( '.slick--vehicle-gallery-thumbs' ).on( 'lazyLoaded', function( evt, slick, $img ) {

				$img
					// Find the parent <picture> tag of img
					.closest('picture')
					// Find <source> tags with data-lazy-srcset attribute
					.find('source[data-lazy-srcset]')
					// Copy data-lazy-srcset to srcset
					.each(function (i: any, $source: any) {
						$source = $($source);
						$source.attr('srcset', $source.data('lazy-srcset'));
					}); 
			} );

			$('.slick--vehicle-gallery-main').on('afterChange', function(event, slick, currentSlide){
				if (currentSlide === 0) {
					$('.slick-prev').css('visibility', 'hidden'); // Hide prev on the first slide
				  } else {
					$('.slick-prev').css('visibility', 'visible'); // Show prev on any other slide
				  }
			  });
		}
	}
}